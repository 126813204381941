import { MDBContainer } from "mdb-react-ui-kit";
import { FC, ReactNode, useEffect, useState } from "react";
import Seo from "@/common/Seo";
import { OpenGraph, OpenGraphMedia } from "next-seo/lib/types";
import {
    subtitleModalNewsletter,
    titleModalNewsletter,
} from "@/utils/mockData/newsletterModalMock";
import { useRouter } from "next/router";
import { useStore } from "@/store/index";
import useIsSubscribedNewsLetter from "@/utils/queries/hooks/useIsSubscribedNewsLetter";
import FooterLayout from "./footer/FooterLayout";
import HeadMeta from "./HeadMeta";
import NavBarLayout from "./NavBar/NavBarLayout";
import ErrorBoundary from "../error/ErrorBoundary";
import CustomModal from "./CustomModal";
import ModalNewsletter from "../home/ModalNewsletter";

const Layout: FC<{
    children: ReactNode;
    metaTags: OpenGraph;
    seoKeywords?: string[];
}> = ({ children, metaTags, seoKeywords }) => {
    const [showModal, setShowModal] = useState(true);

    const email = useStore(state => state.userState.userDetail.email);
    const subscribed = useIsSubscribedNewsLetter(email);

    // useEffect(() => {
    //     // eslint-disable-next-line no-unused-expressions
    //     sessionStorage &&
    //         !sessionStorage.getItem("previouslyVisited") &&
    //         (localStorage.getItem("rememberMe") === "notRemember" ||
    //             !localStorage.getItem("rememberMe")) &&
    //         (setShowModal(true),
    //         sessionStorage.setItem("previouslyVisited", "true"));
    // }, []);

    useEffect(() => {
        // se l'utente è gia iscritto alla newsletter non mostriamo la modale, viceversa si
        if (subscribed) {
            setShowModal(!(subscribed && subscribed.subscription));
        }
    }, [subscribed]);

    const router = useRouter();

    let seoInfo = {
        title: "",
        description: "",
        image: [
            {
                url: "",
            },
        ],
    };

    if (
        router.asPath.includes("/tours-destinations") &&
        router.query.destination !== ""
    ) {
        seoInfo = {
            title: router.query.destination as string,
            description: metaTags.description as string,
            image: metaTags.images as Array<OpenGraphMedia>,
        };
    }
    if (
        router.asPath.includes("/tours-destinations") &&
        router.query.tourType !== ""
    ) {
        seoInfo = {
            title: router.query.tourType as string,
            description: metaTags.description as string,
            image: metaTags.images as Array<OpenGraphMedia>,
        };
    }

    return (
        <>
            <Seo
                seoInfo={seoInfo.title === "" ? metaTags : seoInfo}
                keywords={seoKeywords}
            />
            <HeadMeta />
            <MDBContainer className="ps-0 pe-0" fluid>
                <CustomModal
                    displayModal={showModal}
                    setDisplayModal={() => setShowModal(false)}
                    noTitle
                    classNameBody="ps-lg-0"
                    classNameContent="text-center w-100 border-0 px-2 px-lg-5 position-relative"
                    flagDisable={false}
                    showingDelay={20000}
                >
                    <ModalNewsletter
                        sectionTitle={{
                            title: titleModalNewsletter,
                            subTitle: subtitleModalNewsletter,
                        }}
                        setShowModal={setShowModal}
                    />
                </CustomModal>

                <NavBarLayout setShowModal={setShowModal} />
                <ErrorBoundary>{children}</ErrorBoundary>
                <FooterLayout />
            </MDBContainer>
        </>
    );
};

export default Layout;
