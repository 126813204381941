/* eslint-disable no-return-await */
import axios from "axios";
import { Auth, withSSRContext } from "aws-amplify";
import { TPrivacyData } from "@/models/userPage";
import { TDeleteBookingBody } from "@/models/bookings";
import { TUpdateAvatar } from "@/models/account";
import { FilterResponseTO, SearchResponseTO } from "@/models/search";
import {
    AddNightsResponseTO,
    BookingReadableDetailTO,
    BookingReadableTO,
    ExclusionDaysResponseTo,
    RelatedTourResponseTO,
    TourResponseTO,
} from "@/models/detail";
import {
    BrochureResponseTO,
    FavouriteUpdateResponseTo,
    NavBarResponseTO,
    WishlistResponseTo,
} from "@/models/common/common";
import { HomeResponseTO, VideoreviewsEntity } from "@/models/home";
import { IFaqData } from "@/models/faq";
import { ISignUpTo, ISignUpVerification, IUser } from "@/models/user";
import { getHeaders, getUrl } from "@/utils/queries/utils/utils";
import { ICoupon } from "@/models/checkout";
import { IContactData } from "@/models/contact";
import { CouponResponse } from "@/models/queries/checkout";
import { TourOption } from "typings/mdb-react-ui-kit";
import { ESeoPage, seoContents } from "../mockData/metaMocks";
import { userMocks } from "../mockData/userMocks";
import { FETCH_TIMEOUT, FETCH_TIMEOUT_SERVER } from "../constants";

// eslint-disable-next-line
export const getUserToken = async (ctx?: any) => {
    if (ctx) {
        const SSR = withSSRContext(ctx);
        const user = await SSR.Auth.currentAuthenticatedUser();
        return user?.signInUserSession?.accessToken?.jwtToken;
    }
    const user = await Auth.currentAuthenticatedUser();
    return user?.signInUserSession?.accessToken?.jwtToken;
};

export const getHeadersAuth = async () => {
    const token = await getUserToken();
    return getHeaders(token);
};

export const getNavBarData = async (): Promise<NavBarResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/navbar")
    );
    return data;
};

export const getBrochureData = async (): Promise<BrochureResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/brochoures")
    );
    return data;
};

export const getHomeData = async (): Promise<HomeResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/home")
    );
    return data;
};

export const getVideoReviewsData = async (): Promise<VideoreviewsEntity[]> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/videoreviews")
    );
    return data;
};

export const getRelatedTours = async (
    id: string
): Promise<RelatedTourResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/associated/tour/${id}`)
    );
    return data;
};

export const getRelatedByTitle = async (
    title: string
): Promise<RelatedTourResponseTO> => {
    const { data } = await axios.get(
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            `/tour/associated/${title}`
        )
    );
    return data;
};

export const getSeoInfo = (id: ESeoPage) => {
    return seoContents.find(el => el.id === id);
};

export const getFiltersData = async (): Promise<FilterResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/toursfilter"),
        {
            params: {
                locale: "en",
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const getFaqData = async (): Promise<IFaqData> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/faq")
    );
    return data;
};

export const getTermsData = async (
    id: string
): Promise<{
    success: boolean;
    content: string;
}> => {
    const { data } = await axios.get(
        `https://www.iubenda.com/api/terms-and-conditions/${id}`
    );
    return data;
};
export const getPrivacyData = async (
    id: string
): Promise<{
    success: boolean;
    content: string;
}> => {
    const { data } = await axios.get(
        `https://www.iubenda.com/api/privacy-policy/${id}`
    );
    return data;
};

export const getBookingsData = async (): Promise<BookingReadableTO[]> => {
    const token = await getUserToken();
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/bookings"),
        getHeaders(token)
    );
    return data;
};

export const getBookingsDataDetail = async (
    id: string
): Promise<BookingReadableDetailTO> => {
    const token = await getUserToken();
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/booking/detail/${id}`),
        getHeaders(token)
    );
    return data;
};

export const getUserData = async () => {
    return await userMocks;
};

export const getItalianStoriesData = async (locale = "en") => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/blogs`),
        {
            params: {
                locale,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const getInitialPackageData = async (
    destination?: string | undefined | null,
    tourType?: string | undefined | null,
    locale = "en",
    limit = 6
): Promise<SearchResponseTO[]> => {
    // const destinationFilter = destination ? `&city=${destination}` : "";
    // const tourTypeFilter = tourType ? `&type=${tourType}` : "";
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/tours/filterby`),
        {
            params: {
                locale,
                limit,
                city: destination || null,
                type: tourType || null,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const getAllTours = async (): Promise<
    { _id: string; title: string; status: "ACTIVE" | "INACTIVE" }[]
> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/tours`)
    );

    return data;
};

export const getAllItalianStories = async (): Promise<
    { _id: string; title: string }[]
> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/blogs`)
    );

    return data;
};

export const getFilterPackageData = async (
    selectedDestinations: string[],
    selectedActivities: string[],
    minPrice: number,
    selectedPrice: number,
    selectedTourType: string[],
    tourOption: TourOption,
    locale = "en"
): Promise<SearchResponseTO[]> => {
    // se al servizio passiamo undefined, riceveremo tutti i tour (indipendetemente se siano solo quality, solo premium o entrambi)
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/tours/filterby`),
        {
            params: {
                locale,
                option: tourOption,
                minPrice,
                maxPrice: selectedPrice,
                activity: selectedActivities,
                city: selectedDestinations,
                type: selectedTourType,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const getPackageDetailByTitle = async (
    title: string,
    locale = "en"
): Promise<TourResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/tour/title/${title}`),
        {
            params: {
                locale,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const getPackageDetailData = async (
    id: string,
    locale = "en"
): Promise<TourResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/tour/${id}`),
        {
            params: {
                locale,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const getExcludedDaysByTitle = async (
    title: string,
    timeout: boolean,
    server?: boolean,
    locale = "en"
): Promise<ExclusionDaysResponseTo> => {
    const timeoutParam = timeout
        ? { params: { locale, timeout: "true" } }
        : { params: { locale } };
    const { data } = await axios.get(
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            `/tour/excluded/${title}`
        ),
        {
            ...timeoutParam,
            paramsSerializer: {
                indexes: null, // by default: false
            },
            timeout:
                server && server === true
                    ? FETCH_TIMEOUT
                    : FETCH_TIMEOUT_SERVER,
        }
    );
    return data;
};

export const getExcludedDays = async (
    id: string,
    timeout: boolean,
    server?: boolean,
    locale = "en"
): Promise<ExclusionDaysResponseTo> => {
    const timeoutParam = timeout
        ? { params: { locale, timeout: "true" } }
        : { params: { locale } };
    // console.log(timeoutParam);
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/tour/${id}/excluded`),
        {
            ...timeoutParam,
            paramsSerializer: {
                indexes: null, // by default: false
            },
            timeout:
                server && server === true
                    ? FETCH_TIMEOUT
                    : FETCH_TIMEOUT_SERVER,
        }
    );
    // console.log(data);
    return data;
};

export const getExcludedDaysSession = async (
    id: string,
    server?: boolean,
    locale = "en"
): Promise<ExclusionDaysResponseTo> => {
    try {
        return server && server === true
            ? await getExcludedDays(id, false, true, locale)
            : await getExcludedDays(id, false, false, locale);
    } catch (error) {
        return server && server === true
            ? await getExcludedDays(id, true, true, locale)
            : await getExcludedDays(id, true, false, locale);
    }
};

export const getExcludedDaysSessionByTitle = async (
    title: string,
    server?: boolean,
    locale = "en"
): Promise<ExclusionDaysResponseTo> => {
    try {
        return server && server === true
            ? await getExcludedDaysByTitle(title, false, true, locale)
            : await getExcludedDaysByTitle(title, false, false, locale);
    } catch (error) {
        return server && server === true
            ? await getExcludedDaysByTitle(title, true, true, locale)
            : await getExcludedDaysByTitle(title, true, false, locale);
    }
};

export const getAddNights = async (
    ids: string[]
): Promise<AddNightsResponseTO> => {
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/addnights`),
        {
            params: {
                ids,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

/* eslint-disable */
export const getItalianStoryData = async (
    input: string,
    locale = "en",
    navigationById: boolean
) => {
    /* 
        precedentemente le italian stories venivano recuperate tramite id
        è stata richiesta di playitaly navigare per titolo e non per id
    */
    const { data } = await axios.get(
        // getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/blog/${id}`),
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            navigationById ? `/blog/${input}` : `/blog/title/${input}`
        ),
        {
            params: {
                locale,
            },
            paramsSerializer: {
                indexes: null, // by default: false
            },
        }
    );
    return data;
};

export const postSignUp = async (body: ISignUpTo) => {
    await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/customer/create`),
        body
    );
};

export const verifyCoupon = async ({
    code,
    originalAmount,
    tourId,
}: {
    code: string;
    originalAmount: number;
    tourId: string;
}): Promise<{ coupon: ICoupon; discountedAmount: number }> => {
    const token = await getUserToken();
    const { data } = await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `coupon/discount`),
        {
            couponCode: code,
            amount: originalAmount,
            tourId,
        },
        getHeaders(token)
    );
    const response = data as CouponResponse;
    return {
        coupon: {
            code,
            value: response.discount,
        },
        discountedAmount: response.newPrice,
    };
};

export const verifyAccount = async (value: ISignUpVerification) => {
    await Auth.confirmSignUp(value.username, value.code);
};

export const signOut = async () => {
    await Auth.signOut();
};

export const forgotPassword = async (data: { username: string }) =>
    await Auth.forgotPassword(data.username);

export const forgotPasswordSubmit = async (data: {
    username: string;
    code: string;
    newPassword: string;
}) =>
    await Auth.forgotPasswordSubmit(data.username, data.code, data.newPassword);

export const putUserDetail = async (body: IUser): Promise<string> => {
    const res = await getUserToken();

    const { data } = await axios.put(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/update/customer`),
        body,
        getHeaders(res)
    );
    return data;
};

export const updatePrivacy = async (body: TPrivacyData): Promise<IUser> => {
    const token = await getUserToken();
    const { data } = await axios.put(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/update/privacy`),
        { ...body, terms: true },
        getHeaders(token)
    );
    return data;
};

export const getWishlist = async (): Promise<WishlistResponseTo> => {
    const token = await getUserToken();
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/favourite/tours`),
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return data;
};

export const putFavourite = async (
    tourId: string
): Promise<{ id: string; data: FavouriteUpdateResponseTo }> => {
    const token = await getUserToken();
    const { data } = await axios.put(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/favourite/tour`),
        { tourId },
        getHeaders(token)
    );
    return { id: tourId, data };
};

export const deleteUser = async (): Promise<{ message: string }> => {
    const token = await getUserToken();
    const { data } = await axios.delete(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/customer/delete`),
        getHeaders(token)
    );
    return data;
};

export const postDeleteBooking = async (body: TDeleteBookingBody) => {
    const token = await getUserToken();
    const { transactionId, refundReason } = body;
    const { data } = await axios.post(
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            `/refund/${body.orderId}/payment`
        ),
        {
            transactionId,
            refundReason,
        },
        getHeaders(token)
    );
    return data;
};

export const getSubscribeNewsletter = async (
    email: string
): Promise<{ subscription: boolean }> => {
    const { data } = await axios.get(
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            `/user/newsletter/${email}`
        )
    );
    return data;
};

export const unsubScribeToNewsletter = async (body: { email: string }) => {
    const { data } = await axios.delete(
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            `/unsubscribe/newsletter/${body.email}`
        )
    );
    return data;
};

export const subscribeToNewsletter = async (body: { email: string }) => {
    const { data } = await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/subscribe/newsletter`),
        body
    );
    return data;
};

export const subscribeToNewsletterCustom = async (body: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailCheck: boolean;
    phoneNumberCheck: boolean;
}) => {
    const { data } = await axios.post(
        getUrl(
            `${process.env.NEXT_PUBLIC_BASE_URL}`,
            `/subscribe/newsletter/custom`
        ),
        body
    );
    return data;
};

export const updatePrivacySection = async ({
    dataPrivacy,
    dataNewsletter,
}: {
    dataPrivacy: TPrivacyData;
    dataNewsletter: { email: string; subscribe: boolean; subscribed: boolean };
}): Promise<{ resPrivacy: unknown; subscription: boolean }> => {
    const resPrivacy = await updatePrivacy(dataPrivacy);
    if (dataNewsletter.subscribed && !dataNewsletter.subscribe) {
        await unsubScribeToNewsletter({
            email: dataNewsletter.email,
        });
        return { resPrivacy, subscription: false };
    }
    if (!dataNewsletter.subscribed && dataNewsletter.subscribe) {
        await subscribeToNewsletter({
            email: dataNewsletter.email,
        });
        return { resPrivacy, subscription: true };
    }
    return { resPrivacy, subscription: dataNewsletter.subscribe };
};

export const updateAvatarUser = async (body: TUpdateAvatar) => {
    const token = await getUserToken();
    const { avatar } = body;
    const { data } = await axios.put(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/update/avatar`),
        {
            avatar,
        },
        getHeaders(token)
    );
    return data;
};

export const getContactsData = async (): Promise<IContactData> => {
    const { data } = await axios.get(
        getUrl(process.env.NEXT_PUBLIC_BASE_URL || "", "/contact")
    );
    return data;
};
