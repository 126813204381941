import { useQuery } from "@tanstack/react-query";
import { getSubscribeNewsletter } from "../index";

const useIsSubscribedNewsLetter = (email: string) => {
    const { data } = useQuery({
        queryKey: ["subscribed"],
        queryFn: () => getSubscribeNewsletter(email),
        enabled: !!email,
        refetchOnWindowFocus: false,
    });

    return data;
};

export default useIsSubscribedNewsLetter;
